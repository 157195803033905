import { Item, Submenu } from "react-contexify";
import "../style/ReactContexify.css";

const SEARCH_PLACES = [
    { name: "Youtube", url: "https://www.youtube.com/results?search_query=" },
    { name: "Wikipedia", url: "https://en.wikipedia.org/wiki/Special:Search?search=" },
    { name: "Google", url: "https://www.google.com/search?q=" },
    { name: "Google Scholar", url: "https://scholar.google.com/scholar?q=" },
    { name: "Reddit", url: "https://www.reddit.com/search/?q=" },
    { name: "Bing", url: "https://www.bing.com/search?q=" },
    { name: "DuckDuckGo", url: "https://duckduckgo.com/?q=" },
    { name: "Yahoo", url: "https://search.yahoo.com/search?p=" },
];

const SubmenuSearchAbout = ({ query }) => {
    const handleSearch = (url) => {
        window.open(url + encodeURIComponent(query), "_blank");
    };

    return (
        <Submenu label="Search about on">
            {SEARCH_PLACES.map((place) => (
                <Item key={place.name} onClick={() => handleSearch(place.url)}>
                    {place.name}
                </Item>
            ))}
        </Submenu>
    );
};


export default SubmenuSearchAbout