import React, { useState } from 'react';
import '../style/TopicsGrid.css'; // Import the CSS file

const topics = [
  { name: "Software Development", emoji: "💻" },
  { name: "Psychology", emoji: "🧠" },
  { name: "Music", emoji: "🎵" },
  { name: "Cooking", emoji: "🍳" },
  { name: "Art", emoji: "🎨" },
  { name: "Math", emoji: "➕" },
  { name: "Philosophy", emoji: "🧠" },
  { name: "Literature", emoji: "📚" },
  { name: "History", emoji: "🏛️" },
  { name: "Physics", emoji: "⚛️" },
  { name: "System Design", emoji: "🖥️" },
  { name: "Chemistry", emoji: "🧪" },
  { name: "Biology", emoji: "🧬" },
  { name: "Sociology", emoji: "🧑‍🤝‍🧑" },
  { name: "Anthropology", emoji: "🗿" },
  { name: "Economics", emoji: "💸" },
  { name: "Political Science", emoji: "🏛️" },
  { name: "Engineering", emoji: "⚙️" },
  { name: "Architecture", emoji: "🏗️" },
  { name: "Graphic Design", emoji: "🎨" },
  { name: "Film Studies", emoji: "🎬" },
  { name: "Theater", emoji: "🎭" },
  { name: "Dance", emoji: "💃" },
  { name: "Photography", emoji: "📸" },
  { name: "Linguistics", emoji: "🗣️" },
  { name: "Poetry", emoji: "✍️" },
  { name: "Astronomy", emoji: "🌌" },
  { name: "Geology", emoji: "🪨" },
  { name: "Environmental Science", emoji: "🌍" },
  { name: "Computer Science", emoji: "🖥️" },
  { name: "Robotics", emoji: "🤖" },
  { name: "Game Development", emoji: "🎮" },
  { name: "AI & Machine Learning", emoji: "🤖" },
  { name: "Cryptography", emoji: "🔐" },
  { name: "Cybersecurity", emoji: "🛡️" },
  { name: "Blockchain", emoji: "⛓️" },
  { name: "Space Exploration", emoji: "🚀" },
  { name: "Marine Biology", emoji: "🐠" },
  { name: "Renewable Energy", emoji: "☀️" },
  { name: "Urban Planning", emoji: "🏙️" },
  { name: "Fashion Design", emoji: "👗" },
  { name: "Ethics", emoji: "⚖️" },
  { name: "Cultural Studies", emoji: "🌍" },
  { name: "Entrepreneurship", emoji: "🚀" },
  { name: "Product Design", emoji: "📐" },
  { name: "Ecology", emoji: "🌿" },
  { name: "Mythology", emoji: "🐉" },
  { name: "Storytelling", emoji: "📖" },
  { name: "Mindfulness", emoji: "🧘" },
  { name: "Data Science", emoji: "📊" },
  { name: "Agriculture", emoji: "🌾" },
  { name: "Comedy Writing", emoji: "😂" },
  { name: "Esports", emoji: "🎮" },
  { name: "Forensic Science", emoji: "🕵️‍♂️" },
  { name: "Astrophysics", emoji: "🌠" },
  { name: "Neuroscience", emoji: "🧠" },
  { name: "Sustainability", emoji: "♻️" },
  { name: "Space Engineering", emoji: "🚀" },
  { name: "Climate Change", emoji: "🌡️" },
  { name: "Bioinformatics", emoji: "🧬" },
  { name: "Nanotechnology", emoji: "🔬" },
  { name: "Oceanography", emoji: "🌊" },
  { name: "Paleontology", emoji: "🦖" },
  { name: "Digital Marketing", emoji: "📱" },
  { name: "UX/UI Design", emoji: "🖌️" },
  { name: "Game Theory", emoji: "♟️" },
  { name: "Cognitive Science", emoji: "🧠" },
  { name: "Sound Design", emoji: "🎧" },
  { name: "Animation", emoji: "🎞️" },
  { name: "Cartography", emoji: "🗺️" },
  { name: "Zoology", emoji: "🐾" },
  { name: "Permaculture", emoji: "🌱" },
  { name: "Wildlife Conservation", emoji: "🐘" },
  { name: "Game Mechanics", emoji: "🎮" },
  { name: "Geopolitics", emoji: "🌐" },
  { name: "Cosmology", emoji: "🌌" },
  { name: "Sports Science", emoji: "🏋️" },
  { name: "Behavioral Economics", emoji: "💡" },
  { name: "Pharmacology", emoji: "💊" },
  { name: "Human Evolution", emoji: "🧬" },
  { name: "Cinematography", emoji: "📽️" },
  { name: "Audiology", emoji: "🎙️" },
  { name: "Horticulture", emoji: "🌷" },
  { name: "Biodiversity", emoji: "🦋" },
  { name: "Neuroplasticity", emoji: "🧠" },
  { name: "Typography", emoji: "🔤" },
  { name: "Kinetics", emoji: "🏃" },
  { name: "Voice Acting", emoji: "🎤" },
  { name: "Immersive Storytelling", emoji: "🌌" },
  { name: "Food Science", emoji: "🥗" },
  { name: "Ecosystem Restoration", emoji: "🌳" },
  { name: "Experimental Physics", emoji: "🔭" },
];

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const TopicsGrid = () => {
  const [shuffledTopics] = useState(() => shuffleArray(topics));
  const [showAll, setShowAll] = useState(false);
  const visibleTopics = showAll ? shuffledTopics : shuffledTopics.slice(0, 27);
  
  const handleShowAll = () => {
    setShowAll(true);
  };

  const handleTopicClick = (topic) => {
    const savedTopics = JSON.parse(localStorage.getItem("lastClickedTopics")) || [];
    const updatedTopics = [...new Set([topic, ...savedTopics])].slice(0, 9);
    localStorage.setItem("lastClickedTopics", JSON.stringify(updatedTopics));
    window.location.href = `/${topic.name}`;
  };

  return (
    <div className="topics-grid-container">
      <div className="topics-grid">
        {visibleTopics.map((topic, index) => (
          <div
            onClick={() =>handleTopicClick(topic)}
            key={index}
            className="topic-card d-flex"
          >
            <div>
              <div className="emoji">{topic.emoji}</div>
              <div className="name">{topic.name}</div>
            </div>
          </div>
        ))}
      </div>
      {!showAll && topics.length > 6 && (
        <div className='d-flex justify-content-center mt-3'>
        <button className="navbar-item ms-0 mt-0" onClick={handleShowAll}>
          Show more
        </button>
        </div>
      )}
    </div>
  );
};

export default TopicsGrid;
