export default function getNodeChildrens(node, networkRef) {
  if (!node) return [];

  const children = [];
  // Get all edges from the network data
  const edges = networkRef.current.body.data.edges.get();
  
  for (const edge of edges) {
    // Check if the edge originates from the given node
    if (edge.from === node) {
      const child = networkRef.current.body.data.nodes.get(edge.to);
      if (child) {
        children.push(child);
      }
    }
  }
  
  return children;
};
