import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import '../style/ResponsiveCardSwiper.css';
import fetchHandCards from "../helpers/fetchHandCards";
import { generateRandomKey } from "../helpers/generateRandomKey";
import { IoCloseCircle } from "react-icons/io5";
import { notifyAndInviteToSubscribe } from "./notifyAndInviteToSubscribe";
import focusOnNode from "../helpers/focusOnNode";
import pulseNode from "../helpers/pulseNode";
import { getNodeDataById } from "../helpers/getNodeDataById";

function HandCard({ slide, multiplier, onSlideUp, onUseCardRef, cardGameNodeOnFocus }) {
  const [verticalOffset, setVerticalOffset] = useState(0);
  const startYRef = useRef(null);
  const maximumUpwardMovement = -20;
  const maximumDownwardMovement = 12;

  const handlePointerDown = (e) => {
    startYRef.current = e.clientY;
  };

  const handlePointerMove = (e) => {
    if (startYRef.current !== null) {
      const diff = e.clientY - startYRef.current;
      let clampedDiff = diff;
      if (diff < 0) {
        clampedDiff = Math.max(diff, maximumUpwardMovement);

        if (clampedDiff === maximumUpwardMovement) {
          if (
            !onUseCardRef.current) {
            onSlideUp()
          }
          onUseCardRef.current = cardGameNodeOnFocus
        }

      } else {
        clampedDiff = Math.min(diff, maximumDownwardMovement);
      }
      setVerticalOffset(clampedDiff);
    }
  };

  const handlePointerUp = () => {
    setVerticalOffset(0);
    startYRef.current = null;
    onUseCardRef.current = null;
  };

  return (
    <div
      className={`hand-card ${slide.status ?? ""}`}
      style={{
        transform: `translateY(${verticalOffset}px)`,
        transition: startYRef.current ? "none" : "transform 0.3s ease",
      }}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerUp}
    >
      <div className="d-flex justify-content-between">
        <h1>{slide.title}</h1>
        <div className="card-point-wrapper">
          <div className="card-point-value">
            {multiplier ? multiplier * 100 : 100}
          </div>
          <div className="card-point-tag">PTS</div>
        </div>
      </div>
      <p>{slide.description}</p>
    </div>
  );
}

const ResponsiveCardSwiper = ({
  topics,
  selectedCardId,
  setSelectedCardId,
  cardGameNodeOnFocus,
  handCards,
  setHandCards,
  noticeService,
  refresh,
  setRefresh,
  graph,
  setLevelCardGame,
  swiperRef,
  multiplier,
  setShowLoginModal,
  networkRef,
  pulseNodeTimerRef,
  onUseCard,
  onUseCardRef,
  setLastSelectedNode
}) => {
  const initialized = useRef();

  const resizeCards = () => {
    const handCards = document.querySelectorAll('.responsive-card-swiper');
    handCards.forEach(card => {
      card.style.overflow = null;
    });
  }

  useEffect(() => {
    if (initialized.current && !refresh) {
      return
    }

    initialized.current = true;

    const newGame = !refresh

    loadAndDisplayCards(
      graph,
      newGame,
      noticeService,
      topics,
      setHandCards,
      resizeCards,
      graph.topic,
      setLevelCardGame,
      setShowLoginModal,
      networkRef,
      pulseNodeTimerRef,
      cardGameNodeOnFocus,
      setLastSelectedNode
    );

    setRefresh(false);

  }, [refresh, setRefresh, noticeService, topics, setHandCards, graph, setLevelCardGame, setShowLoginModal])

  if (!handCards || !handCards.length) {
    return
  }

  return (
    <div
      className="responsive-card-swiper"
      style={{
        position: "absolute",
        bottom: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "274px",
        overflow: "hidden"
      }}
    >
      <Swiper
        modules={[EffectCards]}
        effect={"cards"}
        grabCursor={true}
        className="hand-card-swiper"
        cardsEffect={{
          slideShadows: false
        }}
        onSlideChange={(swiper) => {
          setSelectedCardId(swiper.activeIndex);
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        speed={600}
      >
        {handCards.map((slide) => (
          <SwiperSlide key={slide.id}>
            <HandCard slide={slide} multiplier={multiplier} onSlideUp={onUseCard} onUseCardRef={onUseCardRef} cardGameNodeOnFocus={cardGameNodeOnFocus} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="hand-card-multiplier d-flex justify-content-center">
        <div className="pulse">
          <div className={`number ${multiplier > 1 ? "" : "transparent"}`}>{multiplier}x</div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCardSwiper;

function loadAndDisplayCards(
  graph,
  newGame,
  noticeService,
  topics,
  setHandCards,
  resizeCards,
  mainTopic,
  setLevelCardGame,
  setShowLoginModal,
  networkRef,
  pulseNodeTimerRef,
  cardGameNodeOnFocus,
  setLastSelectedNode
) {
  const key = generateRandomKey();

  noticeService.open({
    key: key,
    duration: null,
    content: (
      <div>
        <div className="d-flex justify-content-between px-2">
          <small className="tag">Challenge</small>
          <strong>Wait a second</strong>
        </div>
        <div>Getting your cards...</div>
      </div>
    ),
    closable: true,
    closeIcon: <IoCloseCircle />,
  });

  const fetchData = async () => {
    try {
      const nodeOnFocus = getNodeDataById(cardGameNodeOnFocus, networkRef.current)
      const response = await fetchHandCards({ newGame, mainTopic, topics, nodeOnFocus: nodeOnFocus.label });
      setLevelCardGame(response.level)
      setHandCards(response.cards)

      pulseNode(cardGameNodeOnFocus, networkRef, pulseNodeTimerRef)
      focusOnNode(cardGameNodeOnFocus, networkRef, 0, -50, 1000);
      setLastSelectedNode(nodeOnFocus);

      setTimeout(() => { resizeCards(); }, 50);
      noticeService.close("notifyGetAnswer");

      const newNoticeKey = generateRandomKey();

      noticeService.open({
        key: newNoticeKey,
        duration: 10,
        content: (
          <div>
            <div className="d-flex justify-content-between px-2">
              <small className="tag">Challenge</small>
              <strong>Ready, set, play!</strong>
            </div>
            <div>To use a card, either slide it up or tap on a node to select it.</div>
          </div>
        ),
        closable: true,
        showProgress: false,
        closeIcon: <IoCloseCircle />,
      });
      noticeService.close(key);
    } catch (error) {
      console.error(error)
      noticeService.close(key);
      if (error.status === 429) {
        notifyAndInviteToSubscribe(noticeService, error, setShowLoginModal);
        return
      }
    }
  };

  fetchData();
}