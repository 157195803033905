import constants from "./constants.js";
import { addOrUpdateGraph, getFromIndexedDB } from "./indexedDb.js";

const fetchGraphFromServer = async (topic) => {
    try {
        const response = await fetch(`${constants.fetchGraphUrl}?topic=${encodeURIComponent(topic)}`, {
            credentials: "include"
        });
        if (response.ok) {
            const data = await response.json();
            return data.graph
        }
    } catch (error) {
        console.error("Error fetching graph from server:", error);
    }

    return null;
};

const createDefaultGraph = (topic) => ({
    topic: topic,
    nodes: [
        {
            id: 1,
            label: topic,
            title: topic,
            end_of_the_road: false,
            is_antithesis: false,
            is_main_topic: true,
        },
    ],
    edges: [],
});

const getOrSetGraph = async (topic) => {
    const graphFromServer = await fetchGraphFromServer(topic);
    if (graphFromServer) {
        await addOrUpdateGraph(graphFromServer, topic);
        return graphFromServer;
    }

    const cachedGraph = await getFromIndexedDB(topic);
    if (cachedGraph !== undefined) {
        return cachedGraph;
    }
};

export default getOrSetGraph;