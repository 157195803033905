export default function getRandomNode(networkRef) {
  // Check if networkRef is valid
  if (!networkRef || !networkRef.current) return null;

  // Retrieve all nodes from the network data
  const nodes = networkRef.current.body.data.nodes.get();
  
  // If no nodes exist, return null
  if (!nodes.length) return null;
  
  // Generate a random index and return the corresponding node
  const randomIndex = Math.floor(Math.random() * nodes.length);
  return nodes[randomIndex];
};
