import { IoPlay } from "react-icons/io5";
import { IoIosPause } from "react-icons/io";
import { BiHome, BiUser } from "react-icons/bi";
import { LuLoader } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { RiAddLargeFill } from "react-icons/ri";
import "../style/PromptForm.css";

import GamePointsDisplay from "./GamePointsDisplay";

const Navbar = ({
    isHome = false,
    handlePlayClick,
    playing,
    isFetching = false,
    points,
    setShowLoginModal,
    handleRemoveTopic,
    levelCardGame,
    handleAddNewNode,
    sendNewPrompt,
    newPrompt,
    setNewPrompt
}) => {
    return <div className='navbar'>
        {!isHome ?
            <button title="Go home" onClick={() => window.location.href = '/'} className='navbar-item icon'>
                {<BiHome />}
            </button>
            :
            null}

        {setShowLoginModal ?
            <button title="Your account" onClick={setShowLoginModal} className='navbar-item icon'>
                {<BiUser />}
            </button>
            : null}

        {handlePlayClick ?
            <button title="Toggle physics" onClick={handlePlayClick} className='navbar-item icon'>
                {!playing ? <IoPlay /> : <IoIosPause />}
            </button>
            :
            null}

        {handleAddNewNode ?
            <button
                onClick={handleAddNewNode}
                className="navbar-item icon"
                title="Create a new node"
            >
                <RiAddLargeFill />
            </button>
            :
            null}

        {handleRemoveTopic ?
            <button
                onClick={handleRemoveTopic}
                className="navbar-item icon"
                title="Remove Topic"
            >
                <AiOutlineClose />
            </button>
            :
            null}



        {levelCardGame ?
            <div title="Your level" className="navbar-item disabled">
                <small>Lvl. {levelCardGame}</small>
            </div>
            :
            null}

        {points ?
            <div title="Earn points by solving challenges" className="navbar-item disabled">
                <GamePointsDisplay totalPoints={points} />
            </div>
            :
            null}

        {!isHome && !isFetching && <div className="prompt-form">
            <form onSubmit={sendNewPrompt} className="d-flex">
                <input
                    type="text"
                    className="form-control mr-2 me-2 mt-0 background-dark cursor-text"
                    placeholder="Request a change..."
                    value={newPrompt}
                    onChange={(e) => setNewPrompt(e.target.value)}
                />
                <button type="submit" className="navbar-item ms-0 mt-0 px-3">
                    Send
                </button>
            </form>
        </div>
        }

        {isFetching ? <button onClick={handlePlayClick} className='navbar-item icon blink_me rotating disabled'>
            <LuLoader />
        </button> : null}
    </div>;
}

export default Navbar