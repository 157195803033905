import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Graph from 'react-vis-network-graph';
import constants from '../helpers/constants';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import { useNotification } from 'rc-notification';
import NotificationContainer from './NotificationContainer';
import Modal from './Modal';
import TaskQueue from '../components/TaskQueue';
import { useCloseModalListener } from '../hooks/useCloseModalListener';
import { useScreenResizing } from '../hooks/useScreenResizing';
import { useHandleGraphUpdates } from '../hooks/useHandleGraphUpdates';
import useHandleNewGameState from '../hooks/useHandleNewGameState';
import Chat from './Chat';
import LoginModal from './LoginModal';
import { addOrUpdateGraph, deleteTopic } from '../helpers/indexedDb';
import { motion } from '../helpers/motion';
import deleteTopicFromServer from '../helpers/deleteGraphFromServer';
import { Menu, Item, useContextMenu } from "react-contexify";
import "../style/Graph.css";
import "../style/ReactContexify.css";
import { getNodeContent } from '../helpers/getNodeContent';
import removeNodeAndUpdateGraph from '../helpers/removeNodeAndUpdateGraph';
import removeEdgeAndUpdateGraph from '../helpers/removeEdgeAndUpdateGraph';
import NodeContent from './NodeContent';
import EdgeEntity from '../entities/EdgeEntity';
import addEdgeAndUpdateGraph from '../helpers/addEdgeAndUpdateGraph';
import ResponsiveCardSwiper from './ResponsiveCardSwiper';
import postUseCard from '../helpers/postUseCard';
import notifyChallengeInvite from '../helpers/notifyChallengeInvite';
import useAnimatedPoints from '../hooks/useAnimatedPoints';
import SparkleCanvas from './SparkleCanvas';
import { IoCloseCircle } from 'react-icons/io5';
import buildGraph from '../helpers/buildGraph';
import postGraphToServer from '../helpers/postGraphToServer';
import getOrSetGraph from '../helpers/getOrSetGraph';
import improveGraph from '../helpers/improveGraph';
import SubmenuSearchAbout from './SubmenuSearchAbout';
import { notifyAndInviteToSubscribe } from './notifyAndInviteToSubscribe';
import fitNodesOnScreen from '../helpers/fitNodesOnScreen';
import getNodeChildrens from '../helpers/getNodeChildrens';
import getRandomItem from '../helpers/getRandomItem';
import getRandomNode from '../helpers/getRandomNode';
import getNodeByBestMatch from './getNodeByBestMatch';

const taskQueue = new TaskQueue();
taskQueue.start();

function buildNodeURL(input) {
  let parts = input.split("and").map(s => s.trim());
  if (parts.length < 2) return input;

  return [parts[0], parts[1]].join(" and ");
}


const GameGraph = () => {
  const timerRef = useRef();
  const graphRef = useRef();
  const networkRef = useRef();
  const timeoutRefForGraphUpdate = useRef();
  const handleNodeTimerRef = useRef();
  const pulseNodeTimerRef = useRef();
  const answerNotificationTimerRef = useRef();
  const useHandleGraphUpdatesTimerRef = useRef();
  const notifyChallengeInviteRef = useRef();
  const swiperRef = useRef();
  const buildGraphRef = useRef();
  const onUseCardRef = useRef();

  const navigate = useNavigate();
  const { topic } = useParams();
  const [currentTopic] = useState(topic);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [physicsEnabled, setPhysicsEnabled] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [gameState, setGameState] = useState({});
  const [newGameState, setNewGameState] = useState({});
  const [hover, setHover] = useState(null);
  const [edgeHover, setEdgeHover] = useState(false);
  const [lastHoveredNode, setLastHoveredNode] = useState(false);
  const [edgeSelected, setEdgeSelected] = useState(null);

  const [lastSelectedNode, setLastSelectedNode] = useState({});
  const [lastSelectedNodeParents, setLastSelectedNodeParents] = useState([]);
  const [showChat, setShowNodeContent] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [alreadyLoadedNode, setAlreadyLoadedNode] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showChatAboutContextButton, setShowChatAboutContextButton] = useState(false);
  const [addingNewEdgeFrom, setAddingNewEdgeFrom] = useState(false);
  const [addingNewEdge, setAddingNewEdge] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [startHandCards, setStartHandCards] = useState(false);
  const [handCards, setHandCards] = useState([]);
  const [refreshCardGame, setRefreshCardGame] = useState(false);
  const [levelUpCardGame, setLevelUpCardGame] = useState(false);
  const [pointsCardGame, setPointsCardGame] = useState(0);
  const [levelCardGame, setLevelCardGame] = useState(0);
  const [multiplierCardGame, setMultiplierCardGame] = useState(0);
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [previousScale, setPreviousScale] = useState(1);
  const displayPoints = useAnimatedPoints(pointsCardGame);
  const [newPrompt, setNewPrompt] = useState('');
  const [cardGameNodeOnFocus, setCardGameNodeOnFocus] = useState(0);

  const [notice, contextHolder] = useNotification({
    showProgress: true,
    getContainer: () => document.getElementById('notification-container'),
    motion,
  });

  const [graph, setGraph] = useState({
    nodes: [
      {
        id: 1,
        label: "Loading...",
        title: "Loading...",
        group: "loading",
      }
    ],
    edges: []
  });

  useHandleNewGameState({
    newGameState,
    setNewGameState,
    setGameState,
    networkRef,
    notice,
    pulseNodeTimerRef,
    answerNotificationTimerRef,
    taskQueue,
    topic
  });

  const togglePhysics = () => {
    setPhysicsEnabled(!physicsEnabled);
  };

  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.setOptions({
        physics: { enabled: physicsEnabled },
      });
    }
  }, [physicsEnabled]);

  const handleRemoveTopic = async () => {
    if (!topic) return
    try {
      await deleteTopic(topic)
      await deleteTopicFromServer(topic)
      navigate("/");
    } catch (error) {
      console.error("Error removing topic:", error)
    }
  }

  useHandleGraphUpdates(graph, networkRef, useHandleGraphUpdatesTimerRef);
  useScreenResizing(setScreenWidth, setScreenHeight);
  useCloseModalListener([setShowLoginModal, setShowNodeContent, setShowEdit]);

  const events = {
    selectNode: (event) => {
      setEdgeSelected(null);

      const { nodes } = event;
      const selectedNode = getNodeContent(graph, nodes[0]);
      setLastSelectedNode(selectedNode);

      if (addingNewEdge) {
        if (selectedNode && selectedNode.id !== addingNewEdgeFrom) {
          const edge = new EdgeEntity(addingNewEdgeFrom, selectedNode.id)
          addEdgeAndUpdateGraph(edge, graph, topic, setGraph)
        }

        setAddingNewEdge(false)
        return;
      }
    },
    hoverNode: (event) => {
      if (showContextMenu === false) {
        setLastHoveredNode(event.node)
      }

      setHover(true)
      document.body.style.cursor = 'pointer';
    },
    blurNode: () => {
      if (!addingNewEdge) {
        document.body.style.cursor = 'default';
      }

      setHover(false)
    },
    selectEdge: (event) => {
      setEdgeSelected(event.edges[0])
    },
    hoverEdge: () => {
      setEdgeHover(true)
    },
    blurEdge: () => {
      setEdgeHover(false)
    },
    beforeDrawing: (ctx) => {
      const canvasWidth = ctx.canvas.width;
      const canvasHeight = ctx.canvas.height;

      ctx.save();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.fillStyle = constants.graph.options.nodes.color.background;
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);

      ctx.restore();
    }
  };

  const menuId = "canvas-rightclick"

  const { show, hideAll } = useContextMenu({
    id: menuId,
  });

  const handleDoubleClick = (event) => {
    if (networkRef.current) {
      const network = networkRef.current;
      const currentScale = network.getScale();
      if (!isZoomedIn) {
        setPreviousScale(currentScale);
        network.moveTo({
          scale: 0.75,
          animation: { duration: 500, easingFunction: "easeInOutQuad" },
        });
        setIsZoomedIn(true);
      } else {
        network.moveTo({
          scale: previousScale,
          animation: { duration: 500, easingFunction: "easeInOutQuad" },
        });
        setIsZoomedIn(false);
      }
    }
  }

  const handleContextMenu = (e) => {
    e.preventDefault()

    if (addingNewEdge) {
      return;
    }

    if (hover || edgeHover) {
      show({
        event: e,
      })

      setShowContextMenu(true);
      setShowChatAboutContextButton(hover)
    }
  };

  useEffect(() => {
    const hasGraphChildren = document.querySelector('body .graph') !== null;

    if (hasGraphChildren) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (isFetching) {
      return
    }

    if (!topic) {
      return
    }

    if (!networkRef.current) {
      return
    }

    const topicNode = getNodeByBestMatch(topic, networkRef.current.body.data.nodes.get())
    if (topicNode) {
      setCardGameNodeOnFocus(topicNode.id)
    }
  }, [isFetching, topic, networkRef])

  useEffect(() => {
    if (!networkRef.current) {
      return
    }

    if (buildGraphRef.current) {
      return
    }

    buildGraphRef.current = true

    const fetchGraph = async () => {
      setIsFetching(true)

      const graph = await getOrSetGraph(topic)
      if (graph) {
        graph.topic = topic
        setGraph(graph)
        addOrUpdateGraph(graph, topic);
        setIsFetching(false)
        notifyChallengeInvite(notice, () => { setStartHandCards(true) })
        setTimeout(() => {
          fitNodesOnScreen(networkRef)
        }, 500)
        return
      }

      try {
        const response = await buildGraph({ input: topic })
        response.graph.topic = topic

        const mainNode = getNodeByBestMatch(topic, response.graph.nodes)
        if (mainNode) {
          mainNode.group = "main"
        }

        setGraph(response.graph)
        addOrUpdateGraph(response.graph, topic);
        postGraphToServer(topic, response.graph)
        notifyChallengeInvite(notice, () => { setStartHandCards(true) })
        setTimeout(() => {
          fitNodesOnScreen(networkRef)
        }, 500)
      } catch (error) {
        console.error(error)
        if (error.status && error.status == 429) {
          let message = await error.json()
          notifyAndInviteToSubscribe(notice, { message }, setShowLoginModal)
        }

        const defaultGraph = {
          nodes: [{ id: 1, label: topic, title: topic, group: "main" }],
          edges: []
        }

        setGraph(defaultGraph)
      } finally {
        setIsFetching(false)
      }
    }

    if (!topic) return;
    fetchGraph()

  }, [topic])

  const sendNewPrompt = async (event) => {
    event.preventDefault()
    setIsFetching(true)

    try {
      const response = await improveGraph({ input: newPrompt, graph: graph })

      const mainNode = getNodeByBestMatch(topic, response.graph.nodes)
      if (mainNode) {
        mainNode.group = "main"
      }

      setGraph(response.graph)
      addOrUpdateGraph(response.graph, topic);
      postGraphToServer(topic, response.graph)
    } catch (error) {
      console.error(error)
      if (error.status && error.status == 429) {
        let message = await error.json()
        notifyAndInviteToSubscribe(notice, { message }, setShowLoginModal)
      }
    } finally {
      setIsFetching(false)
      setNewPrompt("")
    }
  }

  return (
    <div className='graph'>
      <div className='absolute z-1 center-y center-x'>
        <div className={`points-big ${displayPoints !== pointsCardGame ? 'popIn' : 'popOut'}`}>
          <p>Total Points: +{displayPoints}</p>
        </div>
        <div className={`level-up-big ${levelUpCardGame ? 'popIn' : 'popOut'}`}>
          <p>Level Up</p>
        </div>
      </div>
      {levelUpCardGame ?
        <div className='absolute z-1'>
          <SparkleCanvas />
        </div> : null}

      <Navbar
        handlePlayClick={togglePhysics}
        playing={physicsEnabled}
        isFetching={isFetching}
        points={pointsCardGame}
        setShowLoginModal={setShowLoginModal}
        handleRemoveTopic={handleRemoveTopic}
        levelCardGame={levelCardGame}
        // handleAddNewNode={handleAddNewNode}
        sendNewPrompt={sendNewPrompt}
        newPrompt={newPrompt}
        setNewPrompt={setNewPrompt}
      />

      <NotificationContainer>
        {contextHolder}
      </NotificationContainer>

      <div
        ref={graphRef}
        onClick={handleContextMenu}
        onDoubleClick={handleDoubleClick}
        style={{ width: "100%", height: "100%" }}
      >
        <Graph
          getNetwork={(network) => { networkRef.current = network; }}
          options={{ ...constants.graph.options, ...{ height: `${screenHeight}px`, width: `${screenWidth}px` } }}
          events={events}
        />
      </div>

      {
        startHandCards && graph.nodes.length > 2 && cardGameNodeOnFocus ?
          <ResponsiveCardSwiper
            graph={graph}
            topics={graph.nodes.map((node) => node.label)}
            cardGameNodeOnFocus={cardGameNodeOnFocus}
            selectedCardId={selectedCardId}
            setSelectedCardId={setSelectedCardId}
            handCards={handCards}
            setHandCards={setHandCards}
            noticeService={notice}
            refresh={refreshCardGame}
            setRefresh={setRefreshCardGame}
            setLevelCardGame={setLevelCardGame}
            swiperRef={swiperRef}
            multiplier={multiplierCardGame}
            setShowLoginModal={setShowLoginModal}
            networkRef={networkRef}
            pulseNodeTimerRef={pulseNodeTimerRef}
            onUseCardRef={onUseCardRef}
            setLastSelectedNode={setLastSelectedNode}
            onUseCard={onUseCard(setHandCards,
              selectedCardId,
              lastSelectedNode,
              notice,
              setStartHandCards,
              setLevelCardGame,
              setMultiplierCardGame,
              setPointsCardGame,
              swiperRef,
              setSelectedCardId,
              cardGameNodeOnFocus,
              networkRef,
              setCardGameNodeOnFocus,
              setLevelUpCardGame,
              setRefreshCardGame,
              setShowContextMenu,
              hideAll,
              onUseCardRef
            )}
          />
          :
          null
      }


      {showChat && (
        <Modal title={`${lastSelectedNode.label}`}>
          <Chat
            topic={graph.topic}
            subject={`${lastSelectedNode.label}`}
            notice={notice}
            setShowLoginModal={setShowLoginModal}
            setLevelUpCardGame={setLevelUpCardGame}
          />
        </Modal>
      )}


      {showEdit && (
        <Modal>
          <NodeContent
            node={lastSelectedNode}
            parentNode={lastSelectedNodeParents}
            graph={graph}
            setGraph={setGraph}
            notice={notice}
            setShowLoginModal={setShowLoginModal}
            setShowNodeContent={setShowEdit}
            setShowChat={setShowNodeContent}
          />
        </Modal>
      )}

      {showLoginModal && (
        <LoginModal notification={notice} topic={topic} />
      )}

      <Menu id={menuId}>
        {handCards.length && !edgeSelected ?
          <Item onClick={onUseCard(setHandCards,
            selectedCardId,
            lastSelectedNode,
            notice,
            setStartHandCards,
            setLevelCardGame,
            setMultiplierCardGame,
            setPointsCardGame,
            swiperRef,
            setSelectedCardId,
            cardGameNodeOnFocus,
            networkRef,
            setCardGameNodeOnFocus,
            setLevelUpCardGame,
            setRefreshCardGame,
            setShowContextMenu,
            hideAll,
            onUseCardRef)}>Use card</Item>
          : null}

        {showChatAboutContextButton ?
          <Item onClick={() => {
            var nodeURL = `/${lastSelectedNode.label}`
            if (graph.topic && graph.topic.length < 15) {
              nodeURL = `/${lastSelectedNode.label === graph.topic ? "" : lastSelectedNode.label + " and"} ${graph.topic}`
            }

            return window.location.href = buildNodeURL(nodeURL);
          }
          }>Open</Item>
          : null}

        {showChatAboutContextButton ?
          <Item onClick={() => {
            setShowNodeContent(true)
            setShowContextMenu(false);
            hideAll()
          }}>Chat about</Item>
          : null}

        {showChatAboutContextButton && addingNewEdge === false ?
          <Item onClick={() => {
            setAddingNewEdge(true)
            setAddingNewEdgeFrom(lastSelectedNode.id)
            document.body.style.cursor = 'pointer'
            setShowContextMenu(false);
            hideAll()
          }}>Connect with</Item>
          : null}

        {edgeSelected ?
          <Item onClick={() => {
            removeEdgeAndUpdateGraph(edgeSelected, topic, graph, setGraph)
            setShowContextMenu(false);
            hideAll()
          }}>Delete arrow</Item>
          : null}

        {showChatAboutContextButton && lastSelectedNode !== 1 ?
          <Item onClick={() => {
            removeNodeAndUpdateGraph(lastSelectedNode.id, topic, graph, setGraph)
            setShowContextMenu(false);
            hideAll()
          }}>Delete</Item>
          : null}

        {showChatAboutContextButton ?
          <SubmenuSearchAbout query={`${lastSelectedNode.label === graph.topic ? "" : lastSelectedNode.label} ${graph.topic}`} />
          : null}
      </Menu>

    </div>
  );
};

export default GameGraph;

function onUseCard(
  setHandCards,
  selectedCardId,
  lastSelectedNode,
  notice,
  setStartHandCards,
  setLevelCardGame,
  setMultiplierCardGame,
  setPointsCardGame,
  swiperRef,
  setSelectedCardId,
  cardGameNodeOnFocus,
  networkRef,
  setCardGameNodeOnFocus,
  setLevelUpCardGame,
  setRefreshCardGame,
  setShowContextMenu,
  hideAll,
  onUseCardRef
) {
  return () => {
    setHandCards((prevCards) => prevCards.map((card) => card.id === selectedCardId ? {
      ...card, ...{
        status: "used"
      }
    } : card
    )
    );

    const postUseCard_ = async () => {
      const response = await postUseCard({
        cardId: selectedCardId,
        topic: lastSelectedNode.label
      });

      if (response.status === 500) {
        notice.open({
          content: (
            <div>
              <div className="d-flex justify-content-between px-2 mb-2">
                <small className="tag">Ops!</small>
                <strong>Something goes wrong</strong>
              </div>
              <div>Please try again.</div>
            </div>
          ),
          duration: 30,
          closable: true,
          showProgress: false,
          closeIcon: <IoCloseCircle />,
        });
        setHandCards([]);
        setStartHandCards(false);
        notifyChallengeInvite(notice, () => { setStartHandCards(true); });
      }

      setLevelCardGame(response.level);

      const status = response.correctAnswer ? "correct" : "wrong";
      setHandCards((prevCards) => prevCards.map((card) => card.id === selectedCardId ? {
        ...card, ...{
          status
        }
      } : card
      )
      );

      setMultiplierCardGame(response.multiplier);

      if (response.correctAnswer) {
        setPointsCardGame(response.points);
        setTimeout(() => {
          setHandCards([]);
          swiperRef.current.activeIndex = 0;
          swiperRef.current.update();
          setSelectedCardId(swiperRef.current.activeIndex);
        }, 400);

        const neighbors = getNodeChildrens(cardGameNodeOnFocus, networkRef);
        if (neighbors.length) {
          const randomNeighbor = getRandomItem(neighbors);
          setCardGameNodeOnFocus(randomNeighbor.id);
        } else {
          const randomNode = getRandomNode(networkRef);
          setCardGameNodeOnFocus(randomNode.id);
        }

        notice.close("notifyGetAnswer");
      } else {
        setTimeout(() => {
          setHandCards((prevHints) => prevHints.map((hint) => hint.id === selectedCardId ? {
            ...hint, ...{
              status: null
            }
          } : hint
          )
          );
        }, 500);
      }

      if (response.levelUp) {
        setLevelUpCardGame(true);
        setTimeout(() => {
          setLevelUpCardGame(false);
        }, 2000);
      }

      setTimeout(() => {
        if (response.needNewHand) {
          setRefreshCardGame(true);
        }
      }, 1000);
    };

    postUseCard_();
    setShowContextMenu(false);
    hideAll();
  };
}