import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { generateRandomKey } from "../helpers/generateRandomKey";

export function notifyAndInviteToSubscribe(noticeService, error, setShowLoginModal) {
  const newNoticeKey = generateRandomKey();
  noticeService.open({
    key: newNoticeKey,
    duration: 30,
    content: (
      <div>
        <div className="d-flex justify-content-between align-items-center mr-1">
          <small className="tag">Ops!</small>
          <strong>You've hit your usage limit.</strong>
        </div>
        <div>{error.message} Subscribe to avoid this error.</div>
        <button
          onClick={() => {
            setShowLoginModal(true);
            noticeService.close(newNoticeKey);
          }}
          type="submit"
          className="navbar-item ms-0 mt-2"
        >
          Upgrade Now
        </button>
      </div>
    ),
    closable: true,
    showProgress: false,
    closeIcon: <IoCloseCircle />,
  });
}
