// Helper function to compute the Levenshtein distance between two strings
function levenshtein(a, b) {
    const matrix = [];
    // Ensure strings are lower case for a case-insensitive match
    a = a.toLowerCase();
    b = b.toLowerCase();

    // increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }
    // increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }
    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,      // deletion
                    matrix[i][j - 1] + 1,      // insertion
                    matrix[i - 1][j - 1] + 1   // substitution
                );
            }
        }
    }
    return matrix[b.length][a.length];
}

function getNodeByBestMatch(inputLabel, nodes) {
    let bestMatch = null;
    let smallestDistance = Infinity;

    nodes.forEach(node => {

        const distance = levenshtein(inputLabel, node.label);
        if (distance < smallestDistance) {
            smallestDistance = distance;
            bestMatch = node;
        }
    });

    return bestMatch;
}


export default getNodeByBestMatch