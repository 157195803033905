import constants from "./constants";

export default async (body) => {
    const response = await fetch(
        constants.buildGraphUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        credentials: "include"
    });

    if (!response.ok) {
        throw response;
    }

    if (response.status === 204) {
        body.status = response.status
        return body
    }

    return await response.json();
}