import { IoCloseCircle } from "react-icons/io5";
import { generateRandomKey } from "./generateRandomKey";

export default function notifyChallengeInvite(noticeService, callBack, messages = {
  title: "Need a challenge?",
  hintText: "Click here to play a game.",
  buttonText: "Start game",
}) {
  const { title, hintText, buttonText } = messages;

  const key = generateRandomKey()

  noticeService.open({
    key: key,
    duration: null,
    content: (
      <div>
        <div className="d-flex justify-content-between align-items-center mr-1">
          <small className="tag">Challenge</small>
          <strong>{title}</strong>
        </div>
        <div>{hintText}</div>
        <button
          onClick={() => {
            callBack();
            noticeService.close(key);
          }}
          type="submit"
          className="navbar-item ms-0 mt-2"
        >
          {buttonText}
        </button>
      </div>
    ),
    closable: true,
    closeIcon: <IoCloseCircle />,
  });
}